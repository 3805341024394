import React, { useEffect } from "react";
import { useEventID } from "wecall-config-lib";
import logo from "../assets/logo.png";
import newslogo1 from "../assets/news-logo01.png";
import newslogo2 from "../assets/news-logo02.png";
import newslogo3 from "../assets/news-logo03.png";
import newslogo4 from "../assets/news-logo04.png";
import newslogo5 from "../assets/news-logo05.png";
import newslogo6 from "../assets/news-logo06.png";
import star from "../assets/stars (2).jpg";
import { Link, Outlet } from "react-router-dom";
import testimonial from "../assets/Frame 517.png";
import user1 from "../assets/Rectangle 5.png";
import user2 from "../assets/user-2.jpeg";
import user3 from "../assets/user-1.jpeg";
import user4 from "../assets/user-4.jpeg";
import InitialScripts from "../script/initialScripts";
import FloatingCard from "../floating-card";
import gasImage from "../assets/Frame 1000005930.png";
import groceryImage from "../assets/grocery.png";
import rentImage from "../assets/Frame 1000005929.png";
import shoppingImage from "../assets/Frame 1000005931.png";
import review from "../assets/Frame 518 (2).webp";
import Accordion from "../component/Accordion";
export default function DefaultPage({ setNumber, cityStateResponse }) {
  const { state, city, zip } = cityStateResponse;
  const accordionData = [
    {
      id: "1",
      title: "1. Who can apply for the subsidy?",
      content: `Eligibility varies by program, typically based on income, employment status, or business size. Check our eligibility page for details.`,
    },
    {
      id: "2",
      title: "2. How do I apply?",
      content: `Fill out the application form on our website and submit the required documents. Visit our application guide for a step-by-step process.`,
    },
    {
      id: "3",
      title: "3. What documents are needed?",
      content: `Common documents include a government-issued ID, proof of income, and for businesses, proof of ownership and financial statements. See the full list on our documentation page.`,
    },
    {
      id: "4",
      title: "4. How long until I receive the subsidy?",
      content: `Processing usually takes 4-6 weeks. You can track your application status online or contact support for updates.`,
    },
  ];

  return (
    <>
      <InitialScripts setNumber={setNumber} />
      <div className="App">
        <div className="mainWrapper">
          <header>
            <div className="container">
              <div className="row">
                <div className="logo-wrapper">
                  <a href="#">
                    <img src={logo}></img>
                  </a>
                </div>
              </div>
            </div>
          </header>
          <div className="hero-section">
            <div className="container">
              <div className="row">
                <Outlet />
              </div>
            </div>
          </div>
          {
            //
            // <div className="logo-strip-section">
            //  <div className="container">
            //    <div className="row">
            //      <div className="logo-strip-headline">
            //        <h1>MENTIONED ON</h1>
            //      </div>
            //      <div className="logo-strip">
            //        <div className="logoitem">
            //          <img src={newslogo1}></img>
            //          <img src={newslogo2}></img>
            //          <img src={newslogo3}></img>
            //          <img src={newslogo4}></img>
            //          <img src={newslogo5}></img>
            //          <img src={newslogo6}></img>
            //        </div>
            //      </div>
            //    </div>
            //  </div>
            // </div>
            //
          }
          <div className="card-section">
            <div className="container">
              <div className="row">
                <div className="custom-container">
                  <div className="icon-pack">
                    <div className="headline-back">
                      <h2>Mentioned On</h2>
                    </div>
                    <div className="logo-icon-pack">
                      <img src={newslogo1}></img>
                      <img src={newslogo2}></img>
                      <img src={newslogo3}></img>
                      <img src={newslogo4}></img>
                      <img src={newslogo5}></img>
                      <img src={newslogo6}></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section1">
            <div className="container">
              <div className="row">
                <div className="section-1-content">
                  <h1>
                    Less than 24 hours left to enroll in benefits in{" "}
                    {cityStateResponse.state}
                  </h1>
                  <p>
                    Thanks to a new government subsidiary program,{" "}
                    {cityStateResponse.state} residents could get $0 Free Health
                    Insurance and up to $1400* allowance card for the everyday
                    expense including No-Cost Vision & No-Cost Dental Benefits.
                  </p>
                  <div className="cta-holder">
                    <Link to={"income"} id="cta-click">
                      I Want My Card
                    </Link>
                  </div>
                </div>

                <div className="item-benefits">
                  <div className="card">
                    <img src={groceryImage} alt="" />
                    <div className="card-content">
                      <h2>Groceries</h2>
                      <p>
                        You are entitled to a free flex card to pay the house
                        rent.
                      </p>
                    </div>
                  </div>
                  <div className="card">
                    <img src={rentImage} alt="" />
                    <div className="card-content">
                      <h2>Rent</h2>
                      <p>
                        You are entitled to a free flex card to pay the house
                        rent.
                      </p>
                    </div>
                  </div>
                  <div className="card">
                    <img src={gasImage} alt="" />
                    <div className="card-content">
                      <h2>Gas</h2>
                      <p>
                        You are entitled to a free flex card to pay the house
                        rent.
                      </p>
                    </div>
                  </div>
                  <div className="card">
                    <img src={shoppingImage} alt="" />
                    <div className="card-content">
                      <h2>Shopping</h2>
                      <p>
                        You are entitled to a free flex card to pay the house
                        rent.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section2">
            <div className="container">
              <div className="row">
                <div className="section2-headline-container">
                  <div className="section2-headline">
                    <h1>
                      Transforming Lives: The Impact of the Subsidy Program
                    </h1>
                    <p>
                      Dive into the remarkable journey of the Subsidy Program, a
                      pivotal initiative reshaping the financial landscape for
                      low-wage Americans. Witness the significant strides made
                      in providing essential support for groceries, rent, gas,
                      and bills. Here's a snapshot of the monumental impact and
                      ongoing progress:
                    </p>
                  </div>
                </div>
                <div className="section2-stat-counter">
                  <div className="statCounter">
                    <div className="statCounterStats">
                      <h3>$43 Million</h3>
                      <h4>Subsidies</h4>
                    </div>
                    <div className="statCounterIntro">
                      <p>
                        The ripple effect of financial empowerment is evident,
                        with millions allocated to uplift households across the
                        nation.
                      </p>
                    </div>
                  </div>
                  <div className="statCounter">
                    <div className="statCounterStats">
                      <h3>250,000+</h3>
                      <h4>People</h4>
                    </div>
                    <div className="statCounterIntro">
                      <p>
                        Many lives have been touched, with more families joining
                        every day to avail the comprehensive benefits
                      </p>
                    </div>
                  </div>
                  <div className="statCounter">
                    <div className="statCounterStats">
                      <h3>1 in 3</h3>
                      <h4>Population</h4>
                    </div>
                    <div className="statCounterIntro">
                      <p>
                        Reflecting the widespread need, a third of the
                        population qualifies for this life-enhancing program,
                        ensuring no one is left behind.
                      </p>
                    </div>
                  </div>
                  <div className="statCounter">
                    <div className="statCounterStats">
                      <h3>482</h3>
                      <h4>Seats</h4>
                    </div>
                    <div className="statCounterIntro">
                      <p>
                        With limited spots remaining, urgency meets opportunity
                        for those aiming to harness the program's full
                        potential.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="testimonial">
            <div className="container">
              <div className="row">
                <div className="testimonial-headline">
                  <h1>Try Yourself Today!</h1>
                </div>
                <div className="testimonial-content-holder">
                  <div className="testimonial-content-text">
                    <p>
                      We strive to offer you the best service, as evidenced by
                      our Trustpilot star rating.
                    </p>
                  </div>
                  <div className="testimonial-content-image">
                    <img src={testimonial} alt="" />
                  </div>
                </div>

                <div className="testimonial-list">
                  <div className="testimonial-row">
                    <div className="testimonial-item">
                      <div className="testimonial-inner">
                        <div className="testimonial-top-rating">
                          <div className="star-rating">
                            <img src={star} alt="" />
                          </div>
                          <div className="review">
                            <img src={review} alt="" />
                          </div>
                        </div>
                        <div className="testimonial-top">
                          <img src={user3} className="userimg"></img>
                          <p>
                            I just received mine fast! They worked tirelessly to
                            secure the financial subsidy I needed, and their
                            dedication to customer satisfaction is unmatched.
                          </p>
                        </div>
                        <div className="testimonial-bottom">
                          <h3>
                            Floyd Miles | <span>Citizen of USA</span>
                          </h3>
                        </div>
                      </div>
                    </div>

                    <div className="testimonial-item">
                      <div className="testimonial-inner">
                        <div className="testimonial-top-rating">
                          <div className="star-rating">
                            <img src={star} alt="" />
                          </div>
                          <div className="review">
                            <img src={review} alt="" />
                          </div>
                        </div>
                        <div className="testimonial-top">
                          <img src={user2} className="userimg"></img>
                          <p>
                            Just in time when i need it the most! I can't thank
                            US Support enough for their prompt assistance. Their
                            team's commitment to me is truly commendable.
                          </p>
                        </div>
                        <div className="testimonial-bottom">
                          <h3>
                            Michael Anderson | <span>Citizen of USA</span>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-row">
                    <div className="testimonial-item">
                      <div className="testimonial-inner">
                        <div className="testimonial-top-rating">
                          <div className="star-rating">
                            <img src={star} alt="" />
                          </div>
                          <div className="review">
                            <img src={review} alt="" />
                          </div>
                        </div>
                        <div className="testimonial-top">
                          <img src={user4} className="userimg"></img>
                          <p>
                            I was in a financial bind, and US Support came to
                            the rescue. Their team's dedication and service to
                            people are top-notch, and I couldn't be happier with
                            their help.
                          </p>
                        </div>
                        <div className="testimonial-bottom">
                          <h3>
                            Olivia Martinez | <span>Citizen of USA</span>
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="testimonial-item">
                      <div className="testimonial-inner">
                        <div className="testimonial-top-rating">
                          <div className="star-rating">
                            <img src={star} alt="" />
                          </div>
                          <div className="review">
                            <img src={review} alt="" />
                          </div>
                        </div>
                        <div className="testimonial-top">
                          <img src={user1} className="userimg"></img>
                          <p>
                            I'm still in awe of how quickly I received mine.
                            This helps me a lot as a minimum wage earner. Their
                            commitment to help you is evident in every step of
                            the process. Highly recommended!
                          </p>
                        </div>
                        <div className="testimonial-bottom">
                          <h3>
                            David Wilson | <span>California, LA</span>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section3">
            <div className="container">
              <div className="row">
                <div className="faq">
                  <h4>FAQs</h4>
                  <p>
                    Get quick answers to your most pressing questions about our
                    subsidy programs below. If you need more information, please
                    contact us.
                  </p>
                </div>

                <div className="accordion">
                  {accordionData.map(({ title, content, id }) => (
                    <Accordion key={id} title={title} content={content} />
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="footer">
            <div className="container top-footer">
              <div className="row">
                <div className="footer-content">
                  <p>
                    {window.domain_settings.websiteName} is not an insurance or
                    operating company but connects individuals with insurance
                    providers and other affiliates. Plans are insured or covered
                    by a Medicare Advantage organization with a Medicare
                    contract and/or a Medicare-approved Part D
                    sponsor.Enrollment in the plan depends on the plan’s
                    contract renewal with Medicare. Possible options include,
                    but are not limited to Major Medical Plans, Short Term
                    Plans, Christian Health Plans, Health Sharing Plans,
                    discount cards and Fixed Indemnity Plans.Descriptions are
                    for informational purposes only and subject to change.
                    {" " + window.domain_settings.websiteName} is not affiliated
                    with any insurance plan nor does it represent or endorse any
                    plan. We do not offer every plan available in your area. Any
                    information we provide is limited to those plans we do offer
                    in your area. Please contact Medicare.gov or 1–800 MEDICARE
                    to get information on all of your options. By using this
                    site, you acknowledge that you have read and agree to the
                    Terms of Service, and Privacy Policy. Not affiliated with
                    the U. S. government or federal Medicare program. A licensed
                    agent may contact you regarding this insurance-related
                    information.
                  </p>
                </div>
              </div>
            </div>
            <div className="container bottom-footer">
              <div className="row">
                <div className="footer-navigation">
                  <div className="footer-copyright">
                    <p>
                      © Copyright {window.domain_settings.websiteTitle} 2023
                    </p>
                  </div>
                  <div className="footer-navigation-wrapper">
                    <ul>
                      <li>
                        <a href="/privacy">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="/terms">Terms of Use</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FloatingCard />
      <LanderSocialEventTrigger />
    </>
  );
}

function LanderSocialEventTrigger() {
  const { getEventId } = useEventID();

  const viewContentKey = "PageView";
  const viewContentKey2 = "PageView2";

  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);

  useEffect(() => {
    if (window.fbcFunc) {
      window.fbcFunc("track", "PageView", { eventID: getEventId() });
      sessionStorage.setItem(viewContentKey, "true");
    }

    if (window.tikTokEvent && params.get("utm_source") === "tiktok") {
      window.tikTokEvent.track("ViewContent", {}, { eventID: getEventId() });
      sessionStorage.setItem(viewContentKey2, "true");
    }
  }, []);
  return <></>;
}
